footer {
  margin: 0 auto;
  text-align: center;

  p {
    font-weight: 100;
    font-size: $font-size-about;
    // margin-bottom: 0;
		margin-bottom: 0.5rem;
		margin-top: 0.5rem;
  }

  a {
    font-weight: $font-regular;
  }

	.footer-gdpr {
		padding-right: 0.5rem;
		color: rgb(2, 198, 252);
		text-decoration: underline;
		font-weight: bold;
	}
}

@media only screen and (max-width: 900px) {
  footer p {
    font-size: 12.5px;
		padding-top: 2rem;
  }
}

// @media only screen and (max-width: 670px) {
// 	.footer-hidden {
// 		display: none;
// 	}
// }
