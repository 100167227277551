//PAGE Contact
.contact-page {
  .wrap-contact {
    text-align: center;

    h6 {
      font-family: $font-family-name;
      letter-spacing: $letter-space-s;
      font-weight: $font-medium;
      margin-top: 0;
    }

    .group {
      display: flex;
      vertical-align: middle;
      justify-content: space-evenly;
    }

    .contact {
      display: flex;
      align-items: center;
      gap: 1rem;

      a {
        font-family: $font-family-text;
        font-size: $font-size-direct;
        letter-spacing: $letter-space-s;
      }
    }
  }

  .wrap-formular {
    text-align: center;

    h6 {
      font-family: $font-family-name;
      font-weight: $font-regular;
      letter-spacing: $letter-space-s;
    }

    form {
      max-width: 70%;
      margin: 0 auto;
    }

    .input-field,
    .input-radio {
      margin-bottom: 0;
    }

    label {
      font-family: $font-family-name;
      font-size: $font-size-text;
      font-weight: $font-regular;
      text-transform: uppercase;
    }

    input,
    input:focus {
      font-family: $font-family-text;
      font-size: $font-size-basic;
      font-weight: $font-regular;
      padding-left: 1rem;
      color: $color-basic;
    }

    textarea {
      font-family: $font-family-text;
      font-size: $font-size-basic;
      font-weight: $font-regular;
      color: $color-basic;
      width: 97%;
      height: 200px;
      background: rgba(217, 217, 217, 0.1);
      border: 1px solid $color-basic;
      border-radius: $border-radius-s;
      resize: vertical;
      padding: 1rem;
      margin-top: 0.5rem;
    }

    textarea::-webkit-input-placeholder {
      font-family: $font-family-text;
      font-size: $font-size-basic;
      font-weight: $font-thin;
      color: $color-basic;
    }

    textarea:focus {
      font-family: $font-family-text;
      font-size: $font-size-basic;
      font-weight: $font-regular;
      color: $color-basic;
    }

    input:focus,
    textarea:focus {
      outline: unset;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    textarea:-webkit-autofill:active,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus,
    select:-webkit-autofill:active {
      font-family: $font-family-text !important;
      font-size: $font-size-basic !important;
      font-weight: $font-regular !important;
      padding-left: 1rem;
      -webkit-text-fill-color: $color-basic !important;
      -webkit-box-shadow: rgba(217, 217, 217, 0.1) !important;
      border: 1px solid $color-gold !important;
      transition: background-color 5000s ease-in-out 0s;
    }

    input::placeholder {
      font-family: $font-family-text;
      font-size: $font-size-basic;
      font-weight: $font-thin;
      color: $color-basic;
    }

    input[type="date"] {
      font-family: $font-family-text;
      font-size: $font-size-basic;
      font-weight: $font-thin;
      text-transform: uppercase;
    }

    ::-webkit-datetime-edit {
      padding-left: 1em;
      color: $color-basic;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      // display: none;
      padding-right: 1rem;
      filter: invert(1);
    }

    .input-field {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      // gap: 5rem;

      .form-control {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-bottom: 1rem;
      }

      input {
        width: 500px;
        height: 40px;
        // margin-bottom: 1rem;
        background: rgba(217, 217, 217, 0.1);
        border: 1px solid $color-basic;
        border-radius: $border-radius-s;
      }

      .label-input {
        font-family: $font-family-name;
        font-size: $font-size-text;
        font-weight: $font-regular;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
      }
    }

    .input-radio {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: row;

      .wrap-radio {
        display: flex;
        margin-top: 0.5rem;
        justify-content: space-between;
        margin-left: 0.5rem;
      }

      .label-type {
        font-family: $font-family-name;
        font-size: $font-size-basic;
        font-weight: $font-light;
      }

      .box-radio {
        width: 500px;
      }

      .wrap-box-left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;
      }

      .type-event {
        text-align: left;
        .form-control {
          display: flex;
          flex-direction: row;
          column-gap: 0.5rem;
          align-items: center;

          input {
            margin: 0;
          }
        }
      }

      .custom-solution {
        text-align: left;
        width: 518px;

        .form-control {
          display: flex;
          flex-direction: row;
          column-gap: 0.5rem;
          align-items: center;

          input {
            margin: 0;
          }
        }

        .wrap-radio {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          row-gap: 0.5rem;
        }

        .form-control {
          width: 155px;
        }
      }

      .product-packages {
        text-align: left;

        .form-control {
          display: flex;
          flex-direction: row;
          column-gap: 0.5rem;
          align-items: center;

          input {
            margin: 0;
          }
        }
      }
    }

    .wrap-textarea {
      text-align: left;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .wrap-gdpr {
      text-align: left;
      margin-bottom: 1rem;

      .form-control {
        display: flex;
        flex-direction: row;
        column-gap: 0.5rem;
        align-items: center;

        input {
          margin: 0;
        }

        label {
          text-transform: none;
        }
      }
    }

    .wrap-submit {
      text-align: left;

      input {
        padding: 0;
        border: 0;
      }
    }
  }

  // .next-service {
  //   p {
  //     margin-top: 0;
  //     margin-bottom: 1rem;
  //   }
  //   .wrap-content {
  //     max-width: 65%;
  //     display: flex;
  //     justify-content: space-between;
  //     margin: auto;
  //     align-items: center;
  //     margin-top: 2rem;
  //   }

  //   .group-left {
  //     img {
  //       max-width: 550px;
  //       height: 360px;
  //       border-radius: $border-radius-m;
  //       filter: $filter;
  //     }
  //   }

  //   .group-right {
  //     max-width: 550px;

  //     .title {
  //       font-family: $font-family-title;
  //       font-size: $font-size-title;
  //       text-transform: uppercase;
  //     }

  //     .btn-primary {
  //       margin: 0;
  //     }
  //   }
  // }

  .contact:hover,
  .contact:focus,
  .contact a:hover,
  .contact a:focus {
    color: $color-gold;
  }

	.wrap-package ul {
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: flex-start;
		gap: 1rem;
		list-style: disc;

		li {
				font-family: "Barlow Condensed", sans-serif;
				font-size: 15px;
				font-weight: 300;
				text-transform: uppercase;
				margin-left: 1rem;
				// color: #FFD700;
		}
	}

}

@media only screen and (max-width: 1800px) {
  .contact-page .wrap-formular form {
    max-width: 80%;
  }

  .contact-page .next-service .wrap-content {
    max-width: 80%;
  }
}

@media only screen and (max-width: 1550px) {
  .contact-page .wrap-formular form {
    max-width: 100%;
  }

  .contact-page .next-service .wrap-content {
    max-width: 85%;
    gap: 3rem;
  }

  .contact-page .next-service .group-left img {
    max-width: 450px;
    height: auto;
  }
}

@media only screen and (max-width: 1300px) {
  .contact-page .wrap-formular .input-field input {
    width: 400px;
  }

  .contact-page .wrap-formular .input-radio .custom-solution {
    width: 420px;
  }

  .contact-page .wrap-formular .input-radio .box-radio {
    width: 420px;
  }

  .contact-page .wrap-formular .input-radio .wrap-radio {
    justify-content: start;
    column-gap: 1rem;
  }

  .contact-page .wrap-formular .input-radio .custom-solution .wrap-radio {
    row-gap: 0.5rem;
    column-gap: 0rem;
    justify-content: space-between;
  }

  .contact-page .wrap-content {
    max-width: 90%;
  }

  .contact-page .wrap-contact .group {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
  }
}

@media only screen and (max-width: 1030px) {
  .contact-page .wrap-contact .contact a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 992px) {
  .contact-page .wrap-formular .input-field input {
    width: 336px;
  }

  .contact-page .wrap-formular .input-radio .custom-solution {
    width: 352.8px;
  }

  .contact-page .wrap-formular .input-radio .box-radio {
    width: 352.8px;
  }

  .contact-page .wrap-formular .input-field {
    gap: 0.67rem;
  }

  .contact-page .next-service .group-left img {
    max-width: 350px;
  }

  .contact-page .next-service .group-right .title {
    font-size: 22px;
  }

  .contact-page .next-service .group-right .content {
    font-size: 16px;
    line-height: 25px;
    margin: 0.5rem 0 1rem 0;
  }
}

@media only screen and (max-width: 850px) {
  .contact-page .wrap-formular .input-field .form-control {
    width: 100%;
  }

  .contact-page .wrap-formular .input-field input {
    width: 100%;
  }

  .contact-page .wrap-formular input,
  .contact-page .wrap-formular input:focus {
    padding-left: 0;
  }

  .contact-page .wrap-formular .input-radio .wrap-box-left {
    width: 100%;
  }

  .contact-page .wrap-formular .input-radio .box-radio {
    width: 100%;
  }

  .contact-page .wrap-formular .input-radio {
    gap: 2rem;
  }

  .contact-page .wrap-formular textarea {
    width: 100%;
    padding: 0rem;
  }

  .contact-page .wrap-formular .input-radio .wrap-radio {
    flex-wrap: wrap;
    row-gap: 1rem;
  }

  .contact-page .wrap-formular .input-radio .wrap-radio {
    justify-content: space-between;
    column-gap: 1rem;
  }

  ::-webkit-input-placeholder {
    padding: 10px;
  }

  :-moz-placeholder {
    padding: 10px;
  }

  ::-moz-placeholder {
    padding: 10px;
  }

  :-ms-input-placeholder {
    padding: 10px;
  }

  ::placeholder {
    padding: 10px;
  }

  input[type="email"]::placeholder {
    /* Firefox, Chrome, Opera */
    text-align: center;
  }
  input[type="text"]::placeholder {
    /* Firefox, Chrome, Opera */
    text-align: center;
  }
  input[type="tel"]::placeholder {
    /* Firefox, Chrome, Opera */
    text-align: center;
  }
  input[type="email"]:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    text-align: center;
  }
  input[type="email"]::-ms-input-placeholder {
    /* Microsoft Edge */
    text-align: center;
  }
  input[type="email"]:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    text-align: center;
  }
  input[type="email"]::-ms-input-placeholder {
    /* Microsoft Edge */
    text-align: center;
  }

  .contact-page .wrap-contact .contact {
    flex-direction: column;
    width: 225px;
  }
}

@media only screen and (max-width: 800px) {
  .contact-page .wrap-formular .input-field {
    gap: 0;
  }

  .contact-page .next-service .wrap-content {
    flex-direction: column;
  }

  .contact-page .next-service .group-left {
    text-align: center;

    img {
      max-width: 65%;
    }
  }

  .contact-page .next-service .group-right {
    max-width: 100%;
    text-align: center;
  }

  .contact-page .next-service .group-right .btn-primary {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 576px) {
  .contact-page .wrap-formular .wrap-gdpr .form-control label {
    font-size: 14px;
  }

  .contact-page .wrap-contact .group {
    gap: 1rem;
    justify-content: center;
  }

  .contact-page .wrap-contact h6 {
    font-size: 19px;
  }

  .contact-page h4 {
    font-size: 25px;
  }

  .contact-page .next-service .wrap-content {
    gap: 1rem;
  }

  .contact-page #submit {
    margin: auto;
		// width: 100%;
		width: -webkit-fill-available;
  }

  .contact-page .wrap-formular h6 {
    margin: 1rem 0 2rem 0;
  }

  .contact-page .wrap-content h6 {
    font-size: 17px;
  }

  .contact-page .next-service .group-right .title {
    font-size: 19px;
  }
}

@media only screen and (max-width: 360px) {
  .contact-page .wrap-formular .wrap-gdpr .form-control {
    flex-direction: column;
    text-align: center;
  }
}
