//PAGE REALIZACIE
.realizacie-page {
  h6 {
    // font-family: $font-family-name;
    font-family: $font-family-title;
    text-transform: uppercase;
    // font-weight: $font-semibold;
    font-weight: $font-extra-light;
    color: $color-basic;
    line-height: $line-height-s;
    letter-spacing: $letter-space-m;
    margin: 2rem 0 0.5rem;
  }

  .group-card {
    display: flex;
    justify-content: space-around;
    margin-bottom: 2.5rem;
  }

  .wrap-portfolio {
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
    align-items: flex-start;

    .group-image {
      max-width: 600px;
      margin-top: 4.5rem;
    }

    .image-gallery-slides {
      border-radius: $border-radius-l;
      filter: $filter;
    }

    .image-gallery-thumbnail-image {
      border-radius: $border-radius-s;
    }

    .aside-direction {
      padding-bottom: 0;
      p {
        font-size: $font-size-text;
      }
      a {
        text-decoration: underline;
      }
    }

    .group-content {
      max-width: 445px;

      h3 {
        font-family: $font-family-title;
        text-transform: uppercase;
        font-weight: $font-regular;
        margin: 0;
      }

      .content {
        height: 250px;
        padding-bottom: 1rem;
        margin: 0.5rem 0 1rem 0;
      }

      .source {
        margin: 0.3rem 0 0.5rem 0;
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 0.93rem;
        padding: 0;
        margin: 0;
        border-right: 0.5px solid hsla(0, 0%, 100%, 0.361);
      }

      li {
        border: 0.5px solid hsla(0, 0%, 100%, 0.361);
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
      }

      span {
        font-family: $font-family-text;
        text-transform: uppercase;
        font-weight: $font-semibold;
        letter-spacing: $letter-space-s;
        padding: 0 1rem;
        opacity: 0.5;
      }

      .container-place {
        height: 260px;
        overflow-y: scroll;
        overflow-x: hidden;

        a {
          font-family: $font-family-text;
          text-transform: uppercase;
          font-weight: $font-semibold;
          letter-spacing: $letter-space-s;
        }
      }

      .container-place li:hover,
      .container-place li:focus {
        background-color: $color-gold;
      }

      .container-place::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      .container-place::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: $border-radius-m;
      }
      .container-place::-webkit-scrollbar-thumb {
        background-color: #dfdfdf;
        border-radius: $border-radius-m;
      }
    }
  }

  .wrap-service .btn-primary {
    width: $btn-width-second;
    margin: 0;
  }

  .price-service-product {
    margin-bottom: 1rem;
  }

  .price-service-product span {
    font-weight: $font-semibold;
    text-transform: uppercase;
  }

  .portfolio-name-mobile {
    font-weight: $font-medium;
    margin-top: 0;
    text-transform: uppercase;
  }

  .wrap-portfolio .aside-direction a {
    margin: 0 0.5rem;
  }
}

@media only screen and (max-width: 1550px) {
  .section-portfolio .wrap-content {
    max-width: 100%;
  }
}

@media only screen and (max-width: 1300px) {
  .realizacie-page .group-card {
    justify-content: space-between;
    gap: 2rem;
  }

  .offer-card {
    width: 300px;
  }

  .realizacie-page .wrap-portfolio .group-image {
    max-width: 500px;
    margin-top: 5rem;
  }

  .realizacie-page .wrap-portfolio .group-content {
    max-width: 385px;

    .content {
      font-size: 16px;
      padding-bottom: 2rem;
    }
  }
}

@media only screen and (max-width: 992px) {
  .realizacie-page .group-card {
    justify-content: space-around;
    gap: 0rem;
    flex-wrap: wrap;
    row-gap: 2rem;
    column-gap: 1rem;
  }

  .section-portfolio .wrap-content {
    max-width: 90%;
  }

  .realizacie-page .wrap-portfolio {
    flex-direction: column;
    align-items: center;
  }

  .realizacie-page .wrap-portfolio .group-image {
    max-width: 550px;
    margin-top: 1rem;
  }

  .realizacie-page .wrap-portfolio .group-content {
    max-width: 100%;
  }

  .realizacie-page article {
    display: flex;
    justify-content: space-between;

    .content {
      max-width: 50%;
    }

    .container-place {
      width: 40%;
    }
  }
}

@media only screen and (max-width: 850px) {
  .offer-card {
    width: 250px;
  }

  .offer-card .h4 {
    font-size: 24px;
  }

  .offer-card .wrap {
    margin-top: 1.35rem;
  }
}

@media only screen and (max-width: 768px) {
  article {
    flex-direction: column;
  }

  .realizacie-page .wrap-portfolio .group-content .content {
    height: max-content;
    max-width: 100%;
  }

  .realizacie-page article .container-place {
    width: 100%;
  }

  .realizacie-page .wrap-portfolio .group-content .content {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 576px) {
  .section-portfolio .wrap-content {
    max-width: 100%;
  }

  .realizacie-page .wrap-portfolio .group-image {
    max-width: 100%;
    margin-top: 0rem;
  }

  .realizacie-page .wrap-service .btn-primary {
    margin: 0 auto;
  }
}
