.btn-primary {
  background: linear-gradient(
    to right,
    $color-basic 50%,
    rgba(255, 255, 255, 0) 50%
  );
}

.btn-primary {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: $btn-width-primary;
  height: $btn-height-primary;
  border-radius: $border-radius-s;
  border: 1px solid $color-basic;
  color: $color-basic;
  font-weight: $font-bold;
  font-size: $font-size-basic;
  text-transform: uppercase;
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.35s cubic-bezier(1, 0, 0, 1);
  svg {
    vertical-align: sub;
    padding-left: 0.5rem;
  }
}

.btn-primary:hover,
.btn-primary:focus {
  background-position: left bottom;
  color: $color-btn;
}

.btn-card {
  background: linear-gradient(
    to right,
    $color-gold 50%,
    rgba(255, 255, 255, 0) 50%
  );
}

.btn-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $btn-width-card;
  height: $btn-height-card;
  border-radius: $border-radius-s;
  border: 1px solid $color-dark-gray;
  color: $color-btn;
  font-weight: $font-bold;
  font-size: $font-size-basic;
  text-transform: uppercase;
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.35s cubic-bezier(1, 0, 0, 1);
  svg {
    vertical-align: sub;
    padding-left: 0.5rem;
  }
}

.btn-card:hover,
.btn-card:focus {
  background-position: left bottom;
  color: $color-basic;
}

.btn-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $btn-width-primary;
  height: $btn-height-primary;
  border-radius: $border-radius-s;
  color: $color-basic;
  font-weight: $font-bold;
  font-size: $font-size-basic;
  text-transform: uppercase;
  background-position: right bottom;
  transition: all 0.35s cubic-bezier(1, 0, 0, 1);
  background-color: rgba(86, 151, 211, 1);
  cursor: pointer;
}

.btn-submit:hover,
.btn-submit:focus {
  // color: $color-btn;
  background-color: $color-btn-blue;
}


.btn-gold {
  background: linear-gradient(
    to right,
    $color-gold 50%,
    rgba(255, 255, 255, 0) 50%
  );
}

.btn-gold {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: $btn-width-primary;
  height: $btn-height-primary;
  border-radius: $border-radius-s;
  border: 1px solid $color-basic;
  color: $color-basic;
  font-weight: $font-bold;
  font-size: $font-size-basic;
  text-transform: uppercase;
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.35s cubic-bezier(1, 0, 0, 1);
  svg {
    vertical-align: sub;
    padding-left: 0.5rem;
  }
}

.btn-gold:hover,
.btn-gold:focus {
  background-position: left bottom;
  color: $color-basic;
}