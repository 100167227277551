.home-page {
	// margin-top: 2rem;
	// height: 86.3vh;
	// height: 89.5vh;;
}

.content-wrap {
	text-shadow: 1px 1px 1px rgb(0 0 0 / 50%);
	// padding: 3rem;
	// box-shadow: 0 2px 6px rgb(177 124 79);
	// backdrop-filter: blur(3px);
	text-align: center;
}

.home-page .border {
  position: absolute;
  top: 23rem;
}

.wrap-home {
  display: flex;
  align-items: center;
  justify-content: center;
	padding-top: 3rem;

  .pre-title {
    font-family: $font-family-title;
    font-size: $font-size-h3;
    text-transform: uppercase;
    color: $color-gray;
    margin-bottom: 0;
  }

  // h1 {
  //   font-family: $font-family-title;
  //   text-transform: uppercase;
  //   font-weight: $font-regular;
  //   margin: 0;
  // }

  .content {
    max-width: 750px;
    font-size: $font-size-text;
    font-weight: $font-regular;
    line-height: $line-height-m;
		margin: auto;
		margin-bottom: 1.5rem;
		margin-top: 1.5rem;
  }

  .video {
    width: 750px;
    height: 400px;
    border-radius: $border-radius-m;
    filter: $filter;

    iframe {
      width: 100%;
      height: inherit;
      border-radius: $border-radius-m;
    }
  }

  .btn-primary {
    margin: 0 auto;
  }
}

.container .aside-home {
  display: flex;
  gap: 0.75rem;
	// margin-top: 15rem;
  // margin-top: 7%;
  // margin-bottom: 4.5%;
}

/** SLIDESHOW **/
@media screen and (max-width: 549px) and (min-width: 320px) {
	.wrap-home-bg-slider {
		picture {
			width: 100%;
		}
	}
}
@media screen and (min-width: 315px) {
	.wrap-home-bg-slider {

		.swiper {
			width: 100%;
			height: auto;
			// height: 100%;
			// filter: unset;
			filter: drop-shadow(1px 2px 5px rgba(0, 0, 0, 0.5)) drop-shadow(4px 2px 125px rgba(255, 255, 255, 0.05));
		}

		.swiper img {
			border-radius: 0;
		}

		.swiper-slide {
			text-align: center;
			font-size: 18px;
			background: #180e0e;

			/* Center slide text vertically */
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.swiper-slide img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}

		.slide-content {
			position: absolute;
			// top: 50%;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
			color: white;
			-webkit-backdrop-filter: blur(5px);
			/* pre Safari */
			backdrop-filter: blur(5px);
			padding: 1.5rem 2rem;
		}

		.title-link {
			font-size: $font-size-title;
			font-weight: $font-bold;
			text-transform: uppercase;
			margin-top: 0;
		}

		.autoplay-progress {
			position: absolute;
			right: 16px;
			bottom: 16px;
			z-index: 10;
			width: 48px;
			height: 48px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: $font-bold;
			color: #fafafa;
		}

		.autoplay-progress svg {
			--progress: 0;
			position: absolute;
			left: 0;
			top: 0px;
			z-index: 10;
			width: 100%;
			height: 100%;
			stroke-width: 4px;
			stroke: #fafafa;
			fill: none;
			stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
			stroke-dasharray: 125.6;
			transform: rotate(-90deg);
		}
	}
}

@media screen and (min-width: 671px) {
	.wrap-home-bg-slider {
		// .swiper {
		// 	position: fixed;
		// 	top: 0;
		// 	z-index: -1;
		// 	opacity: 0.85;
		// }
		// .swiper {
		// 	// height: 750px;
		// 	height: 80vh;
		// }

		.swiper-slide img {
			object-fit: cover;
		}
	}
}

@media screen and (min-width: 1024px) {
	.wrap-home-bg-slider {

		.swiper {
			// height: 750px;
			height: 80vh;
		}

	}
}

/* Media screen MAX */
@media only screen and (max-width: 1300px) {
  .wrap-home .video {
    width: 500px;
    height: 287px;
  }

  .wrap-home {
    gap: 3rem;

    .pre-title {
      font-size: 38px;
    }

    // h1 {
    //   font-size: 90px;
    // }
    // .content {
    //   max-width: 420px;
    // }
  }

  .home-page .border {
    top: 20rem;
  }

	// .container .aside-home {
	// 	margin-top: 10rem;
	// }
}

@media only screen and (max-width: 992px) {
  // .wrap-home .content {
  //   max-width: 370px;
  //   font-size: 16px;
  // }

  .wrap-home .pre-title {
    font-size: 30px;
  }

  // .wrap-home h1 {
  //   font-size: 75px;
  // }

  .home-page .border {
    top: 18.5rem;
  }

	// .wrap-home {
	// 	padding-top: 5rem;
	// }
}

@media only screen and (max-width: 900px) {
  .wrap-home {
    flex-wrap: wrap;
  }

  .wrap-home {
    .pre-title {
      font-size: 38px;
    }

    // h1 {
    //   font-size: 90px;
    // }

    .content {
      max-width: 100%;
      font-size: 18px;
    }
  }

  .home-page .border {
    top: 20rem;
  }

	.background {
		box-shadow: 0 0.1px 1px rgb(177 124 79);
		backdrop-filter: blur(3px);
	}
}

@media only screen and (max-width: 850px) {
  .home-page .border {
    top: 22.5rem;
  }
}

@media only screen and (max-width: 576px) {
	// .wrap-home {
	// 	padding-top: 4rem;
	// }
  .home-page .border {
    display: none;
  }

  .pre-title {
    margin-top: 0;
  }

	// .container .aside-home {
	// 	margin-top: 6rem;
	// }
}

@media only screen and (max-width: 490px) {
	.wrap-home {
		.pre-title {
			font-size: 30px;
		}

		// h1 {
		// 	font-size: 75px;
		// }

		.content {
		  font-size: 16px;
		  line-height: 25px;
		}

		h4 {
			font-size: 27px;
		}
	}
}

@media only screen and (max-width: 414px) {
	.wrap-home h4 {
			font-size: 23px;
	}
}
