//GALLERY SWIPER
.swiper {
  filter: $filter;

  img {
    border-radius: $border-radius-l;
  }
}

// .effects-page .swiper img {
//   width: 500px;
//   height: 350px;
// }

.swiper-scrollbar-drag {
  background-color: $color-basic !important;
  height: 8px !important;
}

@media only screen and (max-width: 1550px) {
  .swiper img {
    height: auto;
  }
}

////// IMAGE SLIDER MIN-WIDTH ///////////////////////////
@media screen and (min-width: 315px) {
  .swiper-container {
    width: 315px;
  }
}

@media screen and (min-width: 600px) {
  .swiper-container {
    width: 600px;
  }
}

@media screen and (min-width: 760px) {
  .swiper-container {
    width: 760px;
  }
}
@media screen and (min-width: 1030px) {
  .swiper-container {
    width: 1030px;
  }
}
///////////////////////////////////////////////////////
