//PAGE ABOUT
.about-page {
	margin-top: 2rem;
  h6 {
    // font-family: $font-family-name;
    font-family: $font-family-title;
    text-transform: uppercase;
    // font-weight: $font-semibold;
    font-weight: $font-extra-light;
    color: $color-basic;
    line-height: $line-height-s;
    letter-spacing: $letter-space-m;
    margin: 2rem 0 0.5rem 0;
  }

  //   .wrap-image {
  //     display: flex;
  //     justify-content: space-around;

  //     img {
  //       border-radius: $border-radius-s;
  //       //   height: 230px;
  //       box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
  //         rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  //       background: $color-basic;
  //       padding: 0.1rem;
  //     }
  //   }

  .team-img {
    max-width: 650px;
    margin-top: 3rem;
    filter: $filter;
		z-index: -1;

    img {
      width: 100%;
      border-radius: $border-radius-m;
    }
  }

  li a {
    font-family: $font-family-name;
    font-size: $font-size-basic;
    text-transform: uppercase;
    letter-spacing: $letter-space-m;
    font-weight: $font-regular;
  }

  .wrap-team {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 2rem;

    ul {
      display: flex;
      padding: 0;
      margin: 0px;
      gap: var(--gap, 3rem);
    }

    .team-group {
      max-width: 445px;
    }

    h3 {
      font-family: $font-family-title;
      text-transform: uppercase;
      font-weight: $font-regular;
      margin: 2rem 0 0.5rem 0;
    }

    h6 {
      font-family: $font-family-title;
      text-transform: uppercase;
      font-weight: $font-regular;
      color: $color-dark-gray;
      margin: 0;
    }

    .description {
      height: 196px;
      font-family: $font-family-text;
      font-size: $font-size-text;
      font-weight: $font-light;
      line-height: $line-height-m;
      margin: 0.5rem 0;
    }

    .title-about {
      font-family: $font-family-name;
      font-size: $font-size-basic;
      font-weight: $font-regular;
      letter-spacing: $letter-space-s;
      text-transform: uppercase;
      margin: 0.5rem 0;
      padding-top: 0.5rem;
      border-top: 1px solid #383b4b;
    }

    .text-about {
      font-family: $font-family-text;
      font-size: $font-size-basic;
      font-weight: $font-extra-light;
      margin: 0;
      margin-bottom: 1rem;
    }
  }
}

@media only screen and (max-width: 1550px) {
  .section-team {
    .wrap-content {
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: 1300px) {
  .about-page .team-img {
    max-width: 500px;
    margin-top: 0;
  }
}

@media only screen and (max-width: 1030px) {
  .section-team {
    .wrap-content {
      max-width: 85%;
    }
  }

  .wrap-team {
    flex-direction: column;
    gap: 3rem;
  }

  .about-page .team-img {
    max-width: 650px;
    margin-top: 1rem;
  }

  .about-page .wrap-team ul {
    justify-content: center;
  }

  .about-page .wrap-team .team-group {
    max-width: 650px;
    text-align: center;
  }
}

@media only screen and (max-width: 576px) {
  .about-page .wrap-team .description {
    height: max-content;
  }

  .about-page .wrap-team h3 {
    font-size: 40px;
  }

  .section-team .wrap-content {
    max-width: 100%;
  }
}

@media only screen and (max-width: 414px) {
  .about-page li a {
    letter-spacing: 2px;
  }

  .about-page .wrap-team ul {
    gap: var(--gap, 2rem);
  }
}

@media only screen and (max-width: 400px) {
  .about-page .wrap-team h3 {
    font-size: 37px;
  }
}

@media only screen and (max-width: 320px) {
  .about-page .wrap-team ul {
    gap: var(--gap, 1rem);
  }

  .about-page .wrap-team h3 {
    font-size: 30px;
  }
}
