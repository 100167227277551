header {
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  // margin-bottom: 7rem;
	// color: rgb(250, 250, 250);
	// background: rgba(255, 255, 255, 0.1);
	// backdrop-filter: blur(1rem);
	// z-index: 1;
	text-shadow: 1px 1px 1px rgb(0 0 0 / 50%);
	box-shadow: 0 0.1px 1px rgb(177 124 79);
	backdrop-filter: blur(6px);
}

.header-wrap {
	width: 85.5%;;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 80px;
}

.logo {
  font-family: $font-family-title;
  font-size: $font-size-h4;
  text-transform: uppercase;
  margin: 0;
}

header ul {
  display: flex;
  padding: 0;
  margin: 0px;
  gap: var(--gap, 3rem);
}

header li {
  font-family: $font-family-name;
  text-transform: uppercase;
  letter-spacing: $letter-space-s;
}

.active-nav {
	border-bottom: 4px solid $color-gold;
}

.menu-icon {
  display: none;
  z-index: 9999;
  border: none;
  background: none;
  color: $color-basic;
}

.navOpen {
  display: flex;
  position: fixed;
  inset: 0px 0px 0px 30%;
  padding: min(30vh, 3.5rem) 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  z-index: 9998;
  background: rgba(255, 255, 255, 0.2);
	// background: rgb(56 56 56 / 72%);
  // backdrop-filter: blur(1rem);
	// -webkit-backdrop-filter: blur(1rem);
	text-shadow: 1px 1px 1px rgb(0 0 0 / 50%);
	box-shadow: 0 0.1px 1px rgb(177 124 79);
	// backdrop-filter: blur(1.5px);
	overflow-y: scroll;
	overflow-x: hidden;
	height: 100vh;
}

.openNav-blurred {
	filter: blur(10px);
	z-index: -1;
	position: relative;
}

.navOpen li {
  width: 100%;
  padding: 1rem 2rem;
  border-bottom: 1px solid rgb(177 124 79);
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 50%;
}

header nav a:hover,
header nav a:focus {
	border-bottom: 4px solid $color-gold;
}

.menu-icon svg {
  vertical-align: middle;
}

@media only screen and (max-width: 1080px) {
	.logo {
			display: block;
	}

	.header-wrap {
		flex-direction: column;
		align-items: center;
		padding: 2rem 0;
	}

  ul {
    gap: var(--gap, 2rem);
  }

  .logo {
    font-size: 26px;
  }
}

// @media only screen and (max-width: 850px) {
//   .header-wrap {
//     flex-direction: column;
//     align-items: center;
//   }

//   .logo {
//     padding-top: 1.5rem;
//   }

//   header li {
//     padding-top: 2rem;

//     a {
//       padding-top: 0;
//     }
//   }

//   header nav a:hover,
//   header nav a:focus {
//     border-top: 0;
//   }

//   .active-nav {
//     border-top: 0;
//     border-bottom: 4px solid $color-gold;
//     padding-bottom: 0.3rem;
//   }
// }

@media only screen and (max-width: 670px) {
	.header-wrap {
		flex-direction: row;
		align-items: center;
		padding: 0;
	}

	header ul {
		display: none;
	}

	.menu-icon {
		display: inherit;
	}
	.header-wrap {
		width: 90%;
	}
	.menu-icon {
		padding: 0;
	}
}