/* Specific styles for active links */
.border-active {
  border-bottom: 2px solid $color-gold;
}

.bg-active {
  background-color: $color-gold;
}

.effect-active {
  color: $color-basic !important;
  border-bottom: 2px solid $color-gold;
}
