.container {
  // max-width: 85%;
  // margin: auto;
}

.wrap-notFound {
  margin: 0 auto;
  text-align: center;
}

.error-form-message {
  margin: 0.5rem 0;
}

.wrap-content {
  max-width: $container;
  margin: 0 auto;
}

.privacy-policy-page {
	// margin-top: 2rem;

	h1 {
		font-size: 40px;
		margin-top: 2rem;
		text-decoration: underline;
	}

	.policy {
		text-decoration: none;
	}

	h2 {
		font-size: 33px;
		margin-top: 2rem;
		margin-bottom: 1rem;
		font-family: $font-family-title;
		font-weight: $font-extra-light;
		text-transform: uppercase;
	}

	h3 {
		font-size: 24px;
		margin-top: 2rem;
		margin-bottom: 1rem;
		font-family: $font-family-title;
		font-weight: $font-extra-light;
		text-transform: uppercase;
	}

	h6 {
			// font-family: $font-family-name;
			font-family: $font-family-title;
			text-transform: uppercase;
			// font-weight: $font-semibold;
			font-weight: $font-extra-light;
			color: $color-basic;
			line-height: $line-height-s;
			letter-spacing: $letter-space-m;
			margin: 2rem 0 0.5rem 0;
		}

		h4 {
			font-size: 26px;
			margin-top: 1rem;
		}

		ul {
			padding-left: 0.5rem;
			margin-bottom: 2.5rem;
		}

	  .zoznam {
			list-style-type: disc;
			padding-left: 1.5rem;
		}
}

.offer-card {
  width: 320px;
  height: 355px;
  border-radius: $border-radius-l;
  background-color: $color-basic;

  .wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 0.85rem;
  }

  img {
    width: 100%;
    height: 220px;
    border-radius: 15px 15px 0 0;
    object-fit: cover;
  }

  .h4 {
    font-size: $font-size-h4;
    font-family: $font-family-name;
    text-transform: uppercase;
    font-weight: $font-semibold;
    color: $color-btn;
    margin: 0;
  }
}

.link-text {
  font-weight: $font-medium;
  text-decoration: underline;
  padding: 0 0 0 0.3rem;
}

h4 {
  font-family: $font-family-title;
  text-transform: uppercase;
  font-weight: $font-regular;
  margin: 0;
  display: inline-block;
}

section {
  margin-bottom: 3rem;
}

.effect-nav, .contact-page {
	margin-top: 2rem;
}

.border {
  display: inline-block;
  width: 5%;
  height: 2px;
  background-color: $color-basic;
  margin-right: 2rem;
  vertical-align: super;
}

.content {
  font-size: $font-size-text;
  font-family: $font-family-text;
  font-weight: $font-light;
  line-height: $line-height-m;
  margin: 0.5rem 0 2.5rem 0;
}

.aside-direction {
  max-width: 90%;
  margin: 0 auto;
  text-align: center;
  padding: 0 0 2.5rem 0;

  p {
    font-family: $font-family-name;
    font-size: $font-size-direct;
    font-weight: $font-semibold;
    line-height: $line-height-s;
    letter-spacing: $letter-space-s;
    text-transform: uppercase;
  }
}

.source {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-bottom: 0.5rem;

  img {
    max-width: 12px;
    height: 12px;
  }

  .link {
    font-family: $font-family-text;
    font-size: $font-size-basic;
    font-weight: $font-medium;
    color: $color-gray;
    text-decoration: underline;
  }
}

@media only screen and (max-width: 1550px) {
}

@media only screen and (max-width: 1300px) {
}

@media only screen and (max-width: 1024px) {
	.privacy-policy-page {
			// margin-top: 2rem;

		h1, h2, h3, h4, h5, h6 {
			text-align: center;
		}
	}
}

@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 850px) {
  .aside-direction p {
    font-size: 18px;
  }

	.privacy-policy-page {
		h1 {
		font-size: 36px;
		}

		h2 {
			font-size: 28px;
		}
	}
}

@media only screen and (max-width: 576px) {
  .border {
    display: none;
  }

  h4 {
    display: block;
    text-align: center;
    font-size: 30px;
  }

  h6 {
    font-size: 22px;
  }

  .content {
    font-size: 16px;
  }

  .about-page,
  .realizacie-page {
    max-width: 90%;
		// max-width: 100%;
    margin: auto;
    text-align: center;

    // .wrap-content,
    // .name-page {
    //   max-width: 100%;
    // }
  }

  .about-page .wrap-team .description {
    font-size: 16px;
  }

  // .name-page h5 {
  //   font-size: 23px;
  // }

  // .name-page span {
  //   font-size: 23px;
  // }

  .source {
    justify-content: center;
  }
}

@media only screen and (max-width: 576px) {
	.effects-page {
		max-width: 90%;
		margin: auto;
	}

	.wrap-content {
		max-width: 100%;
	}

	.home-page .wrap-content {
		max-width: 90%;
	}

	.privacy-policy-page {
		max-width: 95%;
		text-align: center;
	}

	.privacy-policy-page {
		h1 {
			font-size: 28px;
		}

		h2 {
			font-size: 24px;
		}

		h3 {
			font-size: 22px;
		}
	}
}

@media only screen and (max-width: 400px) {
  .about-page h4 {
    font-size: 27px;
  }
}


@media only screen and (max-width: 375px) {
}
