html {
  scroll-behavior: smooth;
  border-sizing: border-box;
}

html::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

html::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: $border-radius-m;
}

html::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
  border-radius: $border-radius-m;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: $color-background-white;
  // background-image: url('../images/bg/concert.jpg');
  // background-image: url('../images/bg/dj.jpg');
  // background-image: url('../images/bg/lights.jpg');
  // background-image: url('../images/bg/sv.png');
  // background-image: url('../images/bg/dj2.jpg');
  // background-image: url('../images/bg/basavel-balik.jpg');
  // background-image: url('../images/bg/what.jpg');
  // background-size: cover;
  // background-repeat: no-repeat;

  color: $color-basic;
  margin: 0 auto;
  font-family: $font-family-text;
}

// .background {
// 	background-image: url('../images/bg/bg-1.jpg');
// 		background-size: cover;
// 		background-repeat: no-repeat;
// 		// height: 100%;
// }

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: $color-basic;
  cursor: pointer;
}

ul {
  list-style: none;
}

h1 {
  // font-size: $font-size-h1;
	font-family: $font-family-title;
	font-size: $font-size-h4;
	text-transform: uppercase;
	margin: 0;
	font-weight: normal;
}

h2 {
  font-size: $font-size-h2;
}

h3 {
  font-size: $font-size-h3;
}

h4 {
  font-size: $font-size-h4;
}

h5 {
  font-size: $font-size-h5;
}

h6 {
  font-size: $font-size-h6;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  cursor: default;
}

@import "~react-image-gallery/styles/scss/image-gallery.scss";

@import url("https://fonts.googleapis.com/css2?family=Bellefair&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
