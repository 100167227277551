//PAGE effects
.effects-page {
	margin-top: 2rem;

	.effect-nav {
		display: none;
	}

  .effect-nav {
    ul {
      display: flex;
      gap: 2rem;
      justify-content: center;
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
    }

    li {
    }

    a {
      font-family: $font-family-text;
      font-size: $font-size-direct;
      font-weight: $font-semibold;
      letter-spacing: $letter-space-m;
      text-transform: uppercase;
      color: $color-gray;
    }

    // li:nth-last-of-type(1) a {
    //   color: $color-gold;
    // }
  }

  .wrap-content {
    text-align: center;
    margin-bottom: 3rem;

    h2 {
      font-family: $font-family-title;
      font-size: $font-size-h2;
      font-weight: $font-regular;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 2rem;
    }

    .video-title {
      //↓ pre source video
      max-width: 100%;
      height: 550px;

      // ↓ pre VIDEO YTB - IFRAME
      // position: relative;
      // width: 100%;
      // padding-top: 28%;

      // iframe {
      //   width: 100%;
      //   height: 100%;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   border: 0;
      // }

      video {
        width: 100%;
        height: inherit;
        object-fit: cover;
        border-radius: $border-radius-l;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
          rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
        filter: $filter;
      }
    }

    article {
      margin-top: 2rem;

      .bubbles-wrap {
        display: flex;
        justify-content: space-between;
        margin: 2rem 0;
        // flex-wrap: wrap;
        // gap: 1rem;

        .bubbles-check {
          display: flex;
          align-items: center;
          gap: 1rem;

          svg {
            width: 65px;
            height: auto;
            color: $color-gold;
          }

          p {
            font-family: $font-family-text;
            font-size: $font-size-direct;
            font-weight: $font-medium;
          }
        }
      }

      .aside-direction {
        padding-bottom: 0;
      }
    }
  }

  .wrap-safety {
    padding-bottom: 0;
  }

  .aside-direction {
    .safety {
      margin: 0.5rem 0;
    }

    .btn-primary {
      width: $btn-width-second;
    }

    .content {
      max-width: 900px;
      margin: 0 auto;
      font-size: $font-size-text !important;
      font-family: $font-family-text !important;
      font-weight: $font-light !important;
      line-height: $line-height-m !important;
      text-transform: none;
      letter-spacing: normal;
    }
  }

  .wrap-instagram {
    ///without images
    a {
      text-decoration: underline;
    }

    h6 {
      font-family: $font-family-text;
      font-weight: $font-semibold;
      letter-spacing: $letter-space-s;
      text-transform: uppercase;
      margin: 3rem 0;
    }

    .group-image {
      display: flex;
      justify-content: space-between;
    }

    .insta-image {
      width: 200px;
      height: 150px;
      background-color: $color-basic;
      border-radius: $border-radius-s;
      // background-image: url(../../images/instagram/martin-os.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      border: 5px solid $color-basic;
    }

    a {
      display: block;
      height: inherit;
    }
  }

  .product-type {
    margin-bottom: 3rem;

    .product-title {
      font-size: $font-size-service;
      font-family: $font-family-text;
      text-transform: uppercase;
      font-weight: $font-semibold;
      letter-spacing: $letter-space-l;
      text-align: left;
      margin: 0 0 0.5rem 0;
    }

    .content {
      text-align: left;
      margin: 0 0 1rem 0;
    }

    .bubbles-wrap {
      display: flex;
      justify-content: flex-start;
      margin: 2rem 0;
      gap: 5rem;

      .bubbles-check {
        display: flex;
        align-items: center;
        gap: 1rem;

        svg {
          width: 65px;
          height: auto;
          color: $color-success;
        }

        p {
          font-family: $font-family-text;
          font-size: $font-size-direct;
          font-weight: $font-medium;
          text-transform: uppercase;
        }
      }
    }
  }

  .content-arrow {
    display: none;
  }
}

@media only screen and (max-width: 1550px) {
  .effects-page .product-type .bubbles-wrap {
    gap: 2rem;
  }
}

@media only screen and (max-width: 1300px) {
  .effects-page .wrap-content article .bubbles-wrap .bubbles-check svg {
    width: 40px;
  }

  .effects-page .product-type .bubbles-wrap {
    gap: 1rem;
  }

  .effects-page .product-type .bubbles-wrap .bubbles-check {
    gap: 0.5rem;
  }

  .effects-page .wrap-content article .bubbles-wrap .bubbles-check p {
    font-size: 16px;
  }

  .effects-page .product-type .bubbles-wrap .bubbles-check svg {
    width: 50px;
  }

  .effects-page .product-type .bubbles-wrap .bubbles-check p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1100px) {
  .effects-page .effect-nav ul {
    gap: 1.25rem;
  }
}

@media only screen and (max-width: 992px) {
  .effects-page .effect-nav ul {
    gap: 1rem;
  }

  .effects-page .effect-nav a {
    font-size: 18px;
    letter-spacing: 2px;
  }
}

@media only screen and (max-width: 900px) {
  .effects-page .product-type .bubbles-wrap {
    justify-content: space-around;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 850px) {
  .effects-page .wrap-content article .bubbles-wrap {
    flex-wrap: wrap;
  }

  .effects-page .effect-nav {
    max-width: 70%;
    margin: auto;
    overflow: auto;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
		margin-top: 3rem;

    ul {
      // width: max-content;
			width: 100%;
      gap: 2rem;
    }
  }

  .effect-nav::-webkit-scrollbar {
    width: 0;
    height: 3px;
  }

  .effect-nav::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: $border-radius-m;
  }

  .effect-nav::-webkit-scrollbar-thumb {
    background-color: #dfdfdf;
    border-radius: $border-radius-m;
  }
}

@media only screen and (max-width: 768px) {
  .effects-page .wrap-content .video-title {
    height: 300px;
  }
}

@media only screen and (max-width: 576px) {
  .effects-page .effect-nav {
    max-width: 85%;
  }

  .effects-page .product-type .bubbles-wrap .bubbles-check {
    width: 195px;
  }

  .effects-page .product-type .product-title {
    text-align: center;
  }
}

@media only screen and (max-width: 414px) {
  .effects-page .wrap-content article {
    margin-top: 2rem;
    // height: 225px;
    overflow-y: auto;
    // border-top: 1px solid $color-gray;
    border-bottom: 1px solid $color-gray;
    padding-bottom: 1rem;
		display: flex;
		flex-direction: column;

		.bubbles-wrap {
				order: 1;
				margin: 0;
		}
		.content {
				order: 2;
		}
		.aside-direction {
			order: 3;
			display: none;
		}
  }

  .effects-page .wrap-content article::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .effects-page .wrap-content article::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: $border-radius-m;
  }

  .effects-page .wrap-content article::-webkit-scrollbar-thumb {
    background-color: #dfdfdf;
    border-radius: $border-radius-m;
  }

  .effects-page .wrap-content h2 {
    font-size: 40px;
  }

  .effects-page .wrap-content .video-title {
    height: 245px;
  }

  .effects-page .content-arrow {
    // display: block;
    position: relative;
    left: 0;
    right: 0;
    bottom: 1.8rem;
		display: none;
  }

  .effects-page .wrap-content .content-arrow {
    background-image: linear-gradient(rgb(88 88 88), rgb(255 255 255 / 15%));
  }

	.effects-page .content {
		margin: 0.5rem 0 0 0;
	}
}

@media screen and (max-width: 375px) {
	.effects-page .wrap-content article .bubbles-wrap .bubbles-check {
		text-align: left;
	}
}

.effect-img {
	width: inherit;
	height: inherit;
	background-repeat: no-repeat;
	background-size: cover;
	background-position-y: center;
	background-position-x: center;
	border-radius: 20px;
}

.effect-plaz {
	background-image:url('../../images/ponuka/title/desktop/plaz-dym-wedding-5.webp');
	// background-image: image-set(url('../../images/ponuka/title/desktop/plaz-dym-wedding-5.webp')1x );
	// background-color: rgba(66, 66, 66, 0.479);;
}

.effect-spark {
	background-image: url('../../images/ponuka/title/desktop/iskry-wedding-4.webp');
	// background-image: image-set(url('../../images/ponuka/title/desktop/iskry-wedding-3.webp')1x );
	// background-color: rgba(66, 66, 66, 0.479);;
}

.effect-co {
	background-image:url('../../images/ponuka/title/desktop/co2-wedding-2.webp');
	// background-image: image-set(url('../../images/ponuka/title/desktop/co2-wedding-2.webp')1x );
	// background-color: rgba(66, 66, 66, 0.479);
}

// .effect-konfety {
// 	background-image: url('../../images/bg/bg-2.jpg');
// }

// .effect-plamene {
// 	background-image: url('../../images/bg/bg-2.jpg');
// }


// .effect-img img {
// 	width: 560px;
// 	height: 315px;
// }